<template>
  <b-card title="Crea un nou propietari">
    <owner-form v-model="owner" />

    <!-- BUTTONS -->
    <b-row class="my-2 d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" :disabled="!canSave" @click="save">
          Crear
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import OwnerForm from "@/views/owners/new-owner/components/OwnerForm.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    OwnerForm,
    BButton,
  },
  data() {
    return {
      owner: null,
    };
  },
  computed: {
    canSave() {
      return (
        !!this.owner?.firstName &&
        !!this.owner?.idType &&
        !!this.owner?.idNumber
      );
    },
  },
  methods: {
    cancel() {
      this.$router.push({ name: "foravila-owners-list" });
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("owner/createOwner", {
          idType: this.owner.idType,
          idNumber: this.owner.idNumber,
          firstName: this.owner.firstName,
          lastName: this.owner.lastName || null,
          email: this.owner.email || null,
          phone: this.owner.phone || null,
          secondaryPhone: this.owner.secondaryPhone || null,
          nationality: this.owner.nationality || null,
          language: this.owner.language || null,
          location: this.owner.location || null,
        })
        .then(() => this.$router.push({ name: "foravila-owners-list" }))
        .catch(() =>
          notifyError(
            "Error",
            "El propietari no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
